import { baseService, BaseService } from '../base-api';



export class EditPlayerService {

  constructor(private baseService: BaseService) {}

  post(body: any) {
    try {
      return this.baseService.genericPostCall(`/data/edit/player`, body)
        .then((response) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const editPlayerService = new EditPlayerService(baseService);