import { baseService, BaseService } from '../base-api';



export class ParentAreaService {

  constructor(private baseService: BaseService) {}

  fetch(parentId: number) {
    try {
      return this.baseService.genericGetCall(`/data/data/areas/parent/${parentId}?include_parent=true`)
        .then((parentArea: any) => {
          return parentArea;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const parentAreaService = new ParentAreaService(baseService);