import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { validateUserService } from "../../api/services/validate.service";
import { auth } from "../../firebase";

const SignInComponent = () => {
  const [emailState, setEmail] = useState("");
  const [passwordState, setPassword] = useState("");
  const [error, setError] = useState<null | string>(null);
  // Router history
  const history = useHistory();

  const signInWithEmailAndPasswordHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    email: string,
    password: string
  ) => {
    event.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        validateUserService.get().then((user) => {
          if (user.user_role === "admin") {
            history.push("/players");
          }
        });
      })
      .catch((error) => {
        setError(error.message);
        console.error("Error signing in with password and email", error);
      });
  };

  const onChangeHandler = (event: any) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign In</h1>
      <div className="border border-sqoutGreen mx-auto w-11/12 md:w-2/4 py-8 px-4 md:px-8 rounded-md">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full"
            name="userEmail"
            value={emailState}
            placeholder="E.g: youremail@gmail.com"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            className="mt-1 mb-3 p-1 w-full"
            name="userPassword"
            value={passwordState}
            placeholder="Your Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          <button
            className="bg-sqoutGreen hover:bg-sqoutGreenHov w-full py-2 text-white rounded-4xl focus:outline-none focus:ring"
            onClick={(event) => {
              signInWithEmailAndPasswordHandler(event, emailState, passwordState);
            }}
          >
            Sign in
          </button>
        </form>
        <p className="text-center my-3">
          <Link
            to="passwordReset"
            className="text-sqoutGreen hover:text-sqoutGreenHov"
          >
            Forgot Password?
          </Link>
        </p>
      </div>
    </div>
  );
};
export default SignInComponent;
