import { ICompetition } from '../../typings/primesports/primesports';
import { baseService, BaseService } from '../base-api';



export class EditCompetitionService {

  constructor(private baseService: BaseService) {}

  post(competitionId: number | undefined, multiplier: number) {
    try {
      return this.baseService.genericPostCall(`/data/edit/competition/`, {
        id: competitionId,
        multiplier: multiplier,
      })
        .then((response: ICompetition) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const editCompetitionService = new EditCompetitionService(baseService);