import { ICompetition } from '../../typings/primesports/primesports';
import { baseService, BaseService } from '../base-api';



export class EditAreaService {

  constructor(private baseService: BaseService) {}

  post(areaId: number | undefined, multiplier: number) {
    try {
      return this.baseService.genericPostCall(`/data/edit/area/`, {
        id: areaId,
        multiplier: multiplier,
      })
        .then((response: ICompetition) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const editAreaService = new EditAreaService(baseService);