import { baseService, BaseService } from '../base-api';



export class IndexProgressService {

  constructor(private baseService: BaseService) {}

  fetch() {
    try {
      return this.baseService.genericGetCall("/search/fill/players/status")
        .then((response: any) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const indexProgressService = new IndexProgressService(baseService);