import React, { useState, createContext, useEffect } from "react";
import { validateUserService } from "../api/services/validate.service";
import { auth } from "../firebase";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";


export const UserContext = createContext({ user: null });

const UserProvider = (props) => {
  const [state, setState] = useState({ user: null, isLoading: true });
  const alertDialog = useAlert();
  const history = useHistory();

  useEffect(() => {
    // Stream to listen to auth state change and set the 1 state with the user
    // which we then pass the context provider to be used all across the app
    //
    setState({ user: null, isLoading: true });
    auth.onAuthStateChanged((userAuth) => {
      validateUserService.get()
      .then((user) => {
        if (user.user_role === "admin") {
          setState({ user: userAuth, isLoading: false });
        } else {
          alertDialog.error(
            "User doesn't have the required role to access the portal."
          );
          history.push("/login");
        }
      })
      // Error while validating the current user, switch back to anonymous
      .catch(() => {
        history.push("/login");
      });
    });
  }, [alertDialog, history]);

  return (
    <UserContext.Provider
      value={{ user: state.user, isLoading: state.isLoading }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
