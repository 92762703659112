import "./App.css";
import SqoutRouter from "./Providers/Router";
import { Windmill } from "@windmill/react-ui";
import { Provider as AlertProvider } from "react-alert";

const alertOptions = {
  timeout: 3000,
  offset: "30px",
  containerStyle: {
    zIndex: 100,
  },
};

const AlertTemplate = ({ style, options, message, close }: any) => (
  <div style={style} className="bg-white shadow-lg p-6">
    {options.type === "info" && (
      <span className="text-lg text-blue-500">{message}</span>
    )}
    {options.type === "success" && (
      <span className="text-lg text-sqoutGreen font-bold">{message}</span>
    )}
    {options.type === "error" && (
      <span className="text-lg text-red-500">{message}</span>
    )}
  </div>
);

function App() {
  return (
    <Windmill>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <SqoutRouter />
      </AlertProvider>
    </Windmill>
  );
}

export default App;
