import { baseService, BaseService } from '../base-api';

export class AreasService {

  constructor(private baseService: BaseService) {}

  fetchAll() {
    try {
      return this.baseService.genericGetCall('/data/data/areas')
        .then((albums: any) => {
          return albums;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const areasService = new AreasService(baseService);