import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "./UserProvider";

// To use this just put it in the router and give it a component to render
// a path and if needed a different custom redirectTo="path"
//
const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { user, isLoading } = useContext(UserContext);

  return (
    <Route
      {...otherProps}
      render={(props) =>
        user && !isLoading ? (
          <Component {...props} />
        ) : isLoading ? <span className="text-center">Loading...</span> : (
          <Redirect
            to={otherProps.redirectTo ? otherProps.redirectTo : "/login"}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
