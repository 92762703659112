import {
  Avatar,

  Modal,
  ModalBody,

  ModalHeader
} from "@windmill/react-ui";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef, useState
} from "react";
import { IArea, ICompetition } from "../../../typings/primesports/primesports";
import MultiplierEditComponent from "../MultiplierEditComponent/MultiplierEditComponent";

interface ICompetitionsModalProps {
  areaCompetitions: Array<ICompetition>;
  area: IArea | undefined;
}

const CompetitionsModal = forwardRef(
  ({ areaCompetitions, area }: ICompetitionsModalProps, ref) => {
    const [modalState, setModal] = useState(false);
    let modalRef: any = useRef();

    const setModalState = (boolean: boolean) => {
      setModal(boolean);
    };
    

    // This let's us expose the setModalState function to the parent
    // so we can handle opening and closing the modal from the parent
    // try to avoid using this too much as it's complex and sometimes
    // hard to read and can cause issues.
    // ## https://reactjs.org/docs/hooks-reference.html#useimperativehandle
    // for reference.
    //
    useImperativeHandle(
      ref,
      () => ({
        setModal: (boolean: boolean) => {
          setModalState(boolean);
        },
      }),
      []
    );


    return (
      <div ref={modalRef}>
        {area ? (
          <Modal isOpen={modalState} onClose={() => setModalState(false)} style={{minHeight: 576}} className="max-h-xl shadow-lg block bg-white p-4 md:w-3/5 overflow-scroll w-full">
            <ModalHeader className="text-2xl border-b-2 text-center pb-2">{area.name}</ModalHeader>
            <ModalBody>
              {areaCompetitions ? areaCompetitions.map(competition => {
                return (
                  <div className="border-b-2 py-2 flex items-center w-100 justify-between" key={competition.id}>
                    <Avatar size="regular" src={`https://dsg-images.com/comps/50x50/${competition.dsg_id}.png`} className="pr-2" />
                    <span>{competition.name}</span>
                    
                    <MultiplierEditComponent competition={competition}/>
                  </div>
                )
              }): <h2>No competitions in this area</h2>}
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    );
  }
);

export default CompetitionsModal;
