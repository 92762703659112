import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow
} from "@windmill/react-ui";
import React, { useRef, useState } from "react";
import { competitionService } from "../../../api/services/competitions.service";
import { IArea, ICompetition } from "../../../typings/primesports/primesports";
import CompetitionsModal from "../AreasCompetitionsModal/AreasCompetitionsModal";
import MultiplierEditComponent from "../MultiplierEditComponent/MultiplierEditComponent";

interface IAreasTableProps {
  areasData?: Array<IArea>;
  worldCompetitionsData?: Array<ICompetition>;
}

const AreasTableComponent = ({
  areasData,
  worldCompetitionsData,
}: IAreasTableProps) => {
  const [openCompetitions, setOpenCompetitions] = useState<ICompetition[]>([]);
  const [areaInState, setArea] = useState<IArea>();

  let competitionModalRef: any = useRef(null);



  const fetchAllCompetitionsInArea = async (area: IArea) => {
    if (area.id) {
      const competitionResponse = await competitionService.fetch(area.id);

      setOpenCompetitions(competitionResponse);

      setArea(area);

      competitionModalRef.current.setModal(true);
    }
  };



  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Multiplier</TableCell>
          </TableRow>
        </TableHeader>

        <TableBody>
          {areasData
            ? areasData.map((area: IArea) => {
                return (
                  <React.Fragment key={area.id}>
                    <TableRow className="cursor-pointer">
                      <TableCell
                        onClick={() => fetchAllCompetitionsInArea(area)}
                      >
                        <div className="flex items-center text-md ">
                          <Avatar
                            src={`https://dsg-images.com/flags/glossy_30x30/${area.dsg_id}.png`}
                          />
                          <span className="ml-2">
                            {area.name}
                          </span>
                        </div>
                      </TableCell>


                      <TableCell>
                        <MultiplierEditComponent area={area} />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            : worldCompetitionsData
            ? worldCompetitionsData.map((competition: ICompetition) => {
                return (
                  <React.Fragment key={competition.id}>
                    <TableRow>
                      <TableCell>
                        <div className="flex items-center text-md cursor-pointer">
                          <Avatar
                            src={`https://dsg-images.com/comps/50x50/${competition.dsg_id}.png`}
                          />
                          <span className="ml-2">
                            {competition.name}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell>
                      <MultiplierEditComponent competition={competition} />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            : null}
        </TableBody>
      </Table>

      <CompetitionsModal
        areaCompetitions={openCompetitions}
        area={areaInState}
        ref={competitionModalRef}
      />
    </TableContainer>
  );
};

export default AreasTableComponent;
