import { Button, Label, Select } from "@windmill/react-ui";
import { isEmpty } from "lodash";
import Moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { areasService } from "../../api/services/areas.service";
import { editPlayerService } from "../../api/services/edit-player.service";
import { searchSinglePlayerService } from "../../api/services/search-player.service";
import xImg from '../../Assets/x-symbol.svg';
import { IArea } from "../../typings/primesports/primesports";
import { IPlayer } from "../../typings/search/search";
import "./PlayerProfile.css";

const PlayerProfile = (props: { playerId: number, removePlayer: Function }) => {
  const [player, setPlayer] = useState<IPlayer>();
  const [formData, setFormData] = useState({});
  const [countries, setCountries] = useState<IArea[]>([]);
  const [playerNationalityForForm, setPlayerNationalityForForm] = useState<
    string | undefined
  >("");
  const [dateValue, setDateValue] = useState<any>();
  const alert = useAlert();
 


  const fetchPlayer = useCallback(async () => {
    try {
      const response: IPlayer = await searchSinglePlayerService.get(
        props.playerId ? props.playerId.toString() : "0"
      );

      if (!isEmpty(response)) {
        setPlayer(response);
        setPlayerNationalityForForm(response.nationality);

        if (response.date_of_birth) {
          const birthDate = new Date(response.date_of_birth);
          setDateValue(birthDate);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [props.playerId]);

  const fetchAreas = useCallback(async () => {
    try {
      const response: IArea[] = await areasService.fetchAll();
      setCountries(response);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    fetchAreas();

    fetchPlayer();
  }, [props.playerId, fetchPlayer, fetchAreas]);

  const editFormState = (event: any) => {
    event.persist();
    setFormData((formData) => ({
      ...formData,
      id: player?.player_id,
      [event.target.name]: event.target.value,
    }));
  };

  const setNationality = (e: any) => {
    setPlayerNationalityForForm(e.target.value);
    editFormState(e);
  };

  const setDateOfBirth = (date: any) => {
    setDateValue(date);

    const dateString = Moment(date).format("yy-MM-DD");

    setFormData((formData) => ({
      ...formData,
      id: player?.player_id,
      date_of_birth: dateString,
    }));
  };

  const submitEditPlayer = () => {
    editPlayerService
      .post(formData)
      .then(() => {
        alert.success("Player edit success");
      })
      .catch((error) => console.error(error));
  };

  return (
    <React.Fragment>
      {!isEmpty(player || countries) ? (
        <>
         <div className="flex-col w-full md:flex  md:flex-col justify-center items-center">
          
          <div className="grid md:grid-cols-2 grid-rows-1 self-center pt-6 justify-center">
            <img
              className="w-48"
              src={`https://www.dsg-images.com/players/150x150/${player?.dsg_id}.png`}
              alt="player profile"
            />

            <div className="grid gap-2 grid-cols-3 w-96 md:w-full">
              <h2 className="text-xl col-span-full self-center">
                {player?.common_name}
              </h2>

              <div className="col-span-1 self-end justify-self-start">
                <span>
                  <h3 className="text-gray-500 text-xs">Nationality: </h3>
                  <h5> {player?.nationality}</h5>
                </span>
                <span>
                  <h3 className="text-gray-500 text-xs">Place of birth: </h3>
                  <h5> {player?.place_of_birth}</h5>
                </span>
                <span>
                  <h3 className="text-gray-500 text-xs">Date of birth:</h3>
                  <h5> {player?.date_of_birth}</h5>
                </span>
              </div>

              <div className="row-span-1 justify-self-end self-end">
                <span>
                  <h3 className="text-gray-500 text-xs"> Height: </h3>
                  <h5> {player?.height}cm</h5>
                </span>
                <span>
                  <h3 className="text-gray-500 text-xs"> Weight: </h3>
                  <h5> {player?.weight}kg</h5>
                </span>
                <span>
                  <h3 className="text-gray-500 text-xs"> Position: </h3>
                  <h5>{player?.position}</h5>
                </span>
              </div>
              <div className="row-span-1 self-end">
                <span>
                  <h3 className="text-gray-500 text-xs"> Foot: </h3>
                  <h5>{player?.foot?.toUpperCase()}</h5>
                </span>
              </div>
            </div>
          </div>

          <div className="player-profile-body self-center mt-16 border-2 p-6 rounded-lg">
            <div className="grid grid-cols-2 gap-4 gap-x-16">
              <h2 className="text-2xl col-span-2">Modify Player</h2>

              <div className="col-span-1">
                <Label className="flex flex-col mt-2">
                  <span>Height</span>
                  <input
                    name="height"
                    className="mt-1 border-2 rounded p-1"
                    defaultValue={player?.height}
                    onChange={editFormState}
                  />
                </Label>
                <Label className="flex flex-col">
                  <span>Weight</span>
                  <input
                    className="mt-1 border-2 rounded p-1"
                    defaultValue={player?.weight}
                    name="weight"
                    onChange={editFormState}
                  />
                </Label>
                <Label className="mt-2">
                  <span>Foot</span>
                  <Select
                    css={{}}
                    className="mt-1 border-2 rounded p-1"
                    name="foot"
                    onChange={editFormState}
                  >
                    {player?.foot === "left" || player?.foot === "Left" ? (
                      <React.Fragment>
                        <option>Left</option>
                        <option>Right</option>
                        <option>Both</option>
                      </React.Fragment>
                    ) : player?.foot === "right" || player?.foot === "Right" ? (
                      <React.Fragment>
                        <option>Right</option>
                        <option>Left</option>
                        <option>Both</option>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <option>Both</option>
                        <option>Right</option>
                        <option>Left</option>
                      </React.Fragment>
                    )}
                  </Select>
                </Label>
              </div>

              <div className="col-start-2">
                <Label>
                  <span>Nationality</span>
                  <Select
                    css={{}}
                    className="mt-1 border-2 p-1 rounded"
                    name="nationality"
                    value={playerNationalityForForm}
                    onChange={(e) => setNationality(e)}
                  >
                    {countries?.map((country: IArea) => (
                      <option key={country.country_code}>{country.name}</option>
                    ))}
                  </Select>
                </Label>
                <Label className="mt-2">
                  <span>Date of birth</span>
                </Label>
                <div className="border-2 w-100 rounded">
                  <DatePicker
                    onChange={(date: any) => setDateOfBirth(date)}
                    value={dateValue}
                    name="date_of_birth"
                    maxDate={new Date()}
                    className="w-100"
                    calendarClassName=""
                    clearIcon={null}
                  />
                </div>
                <Label className="flex flex-col mt-2">
                  <span>Instagram</span>
                  <input
                    name="insagram"
                    className="mt-1 border-2 rounded p-1"
                    defaultValue={player?.instagram}
                    onChange={editFormState}
                  />
                </Label>
                <Label className="flex flex-col mt-2">
                  <span>Twitter</span>
                  <input
                    name="twitter"
                    className="mt-1 border-2 rounded p-1"
                    defaultValue={player?.twitter}
                    onChange={editFormState}
                  />
                </Label>
              </div>
              <Button
                className="col-span-2 bg-sqoutGreen mt-6"
                onClick={submitEditPlayer}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
        </>
 
      ) : (
        <h2> Loading... </h2>
      )}
    </React.Fragment>
  );
};

export default PlayerProfile;
