import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

let firebaseConfig;

switch ( window.location.hostname ) {
  case 'localhost':
  case 'sqout-admin-app-tst.firebaseapp.com':
  case 'sqout-admin-app-tst.web.app':
    console.info('[FIREBASE] Using configuration for TEST');

    firebaseConfig = {
      apiKey: "AIzaSyBlNrJgM2CBcDRsnX0uiikBtaulmHkBB3w",
      authDomain: "primesport-sqout.firebaseapp.com",
      databaseURL: "https://primesport-sqout.firebaseio.com",
      projectId: "primesport-sqout",
      storageBucket: "primesport-sqout.appspot.com",
      messagingSenderId: "255817077911",
      appId: "1:255817077911:web:e1b72621416f78cf0245d2",
      measurementId: "G-QTJH8YS7SJ",
    };
    break;

  default:
    console.info('[FIREBASE] Using configuration for PROD');

    firebaseConfig = {
      apiKey: "AIzaSyCWa5_NO0xhCFDswxEa_FwddhUvQPfuv80",
      authDomain: "sqout-prod.firebaseapp.com",
      projectId: "sqout-prod",
      storageBucket: "sqout-prod.appspot.com",
      messagingSenderId: "144825306850",
      appId: "1:144825306850:web:32ada31a3dfd6336dc98bb",
      measurementId: "G-ERBDFCWTY9"
    };
}

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

// In case we need to use firestore this is the function for it
// export const firestore = firebase.firestore();
