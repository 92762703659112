import { baseService, BaseService } from '../base-api';



export class SearchPlayersService {

  constructor(private baseService: BaseService) {}

  post(playerName: string, skip: number, limit: number) {

    try {
      return this.baseService.genericPostCall(`/search/search/players`, {
        query: playerName,
        skip: skip,
        limit: limit
      })
        .then((response: any) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const searchPlayersService = new SearchPlayersService(baseService);