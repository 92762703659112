import { ICompetition } from './../../typings/primesports/primesports.d';
import { baseService, BaseService } from '../base-api';

export class CompetionService {

  constructor(private baseService: BaseService) {}

  fetch(areaId: number) {
    try {
      
      return this.baseService.genericGetCall(`/data/data/competition/area/${areaId}`)
        .then((competitions: ICompetition[]) => {
          return competitions;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const competitionService = new CompetionService(baseService);