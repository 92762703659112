import { baseService, BaseService } from '../base-api';



export class SearchSinglePlayerService {

  constructor(private baseService: BaseService) {}

  get(playerId: string) {
    try {
      return this.baseService.genericGetCall(`/search/search/player/${playerId}`)
        .then((response: any) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const searchSinglePlayerService = new SearchSinglePlayerService(baseService);