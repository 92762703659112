import React from "react";

const Teams = () => {
  return (
    <React.Fragment>
      <h2>Teams</h2>
    </React.Fragment>
  );
};

export default Teams;
