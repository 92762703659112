import { isUndefined } from 'lodash';
import { auth } from "../firebase.js";
// Deployment config setup based on the hostname of the frontend
//
export type TDeploymentTarget = 'local' | 'test' | 'prod';
export type TSortDirection = 'ASC' | 'DESC';

interface IDeploymentConfig {
  basePath: string;
}

let deploymentTarget: TDeploymentTarget;

switch (window.location.hostname) {
  case 'localhost':
    deploymentTarget = 'local';
    break;

  case 'sqout-admin-app-tst.firebaseapp.com':
  case 'sqout-admin-app-tst.web.app':
    deploymentTarget = 'test';
    break;

  default:
    deploymentTarget = 'prod';
}

const API_END_POINTS: { [key in TDeploymentTarget]: IDeploymentConfig } = {
  local: {
    basePath: 'https://api-tst-sqout.springtree.io',
  },
  test: {
    basePath: 'https://api-tst-sqout.springtree.io',
  },
  prod: {
    basePath: 'https://api-sqout.springtree.io',
  },
};

// Export the resulting target and config so it can be imported elsewhere
//
export const DEPLOYMENT_TARGET = deploymentTarget;
export const API_ENDPOINT_CONFIG = API_END_POINTS[DEPLOYMENT_TARGET];
export const API_BASEPATH = API_ENDPOINT_CONFIG.basePath;

interface IQueryParams {
  [key: string]: string | number | boolean;
}

export class BaseService {
  basePath: string;
  requestHeaders: Headers;
  constructor() {

    this.basePath = API_BASEPATH;

    // set default headers for generic calls
    this.requestHeaders = new Headers({
      'Content-Type': 'application/json',
    });
  }

  private async makeApiCall(request: Request) {

    const bearerToken = await auth.currentUser?.getIdToken();

    if (!bearerToken) {
      return Promise.reject(`no bearer token set for url ${request.url}`);
    }

    request.headers.append('Authorization', `Bearer ${bearerToken}`);

    return fetch(request).then(async (response: Response) => {
      let parsedResponse;

      try {
        if (response.ok) {
          const responseContentType = response.headers.get('content-type');
          if (responseContentType && responseContentType.includes('json')) {
            parsedResponse = await response.json();
            return Promise.resolve(parsedResponse);
          }
          return Promise.resolve();
        }

        // if the request is not ok by fetch it means the status code is not between 200 and 299 and we should return the error of the api
        //
        const responseText = JSON.parse(await response.text());
        if (response.status === 401 || response.status === 403) {
           auth.signOut();
        }

        return Promise.reject(responseText);
      } catch (e) {
        return Promise.reject(`Couldn't parse response for url ${request.url}`);
      }
    }).catch((error: any) => {
      return Promise.reject(error);
    });
  }

  appendQueryParam(url: string, paramKey: string, paramValue: any) {
    if ((typeof paramValue === 'string' && paramValue !== '') || (typeof paramValue === 'number' && !isNaN(paramValue)) || (typeof paramValue === 'boolean')) {
      // if we don't have a question mark in the url it means we don't have query params yet
      // so we need to add the first one
      //
      if (!url.includes('?')) {
        return `${url}?${paramKey}=${paramValue}`;
      }

      return `${url}&${paramKey}=${paramValue}`;
    }

    return url;
  }

  private generateUrl(url: string) {
    if (url.startsWith('/')) {
      return this.basePath + url;
    }
    return `${this.basePath}/${url}`;
  }

  genericGetCall(url: string): Promise<any> {
    const request = new Request(this.generateUrl(url), {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return this.makeApiCall(request);
  }

  genericListCall(url: string, limit: number, skip: number, sortField: string = 'created_at', sortDirection: TSortDirection = 'DESC',
    optionalParams?: IQueryParams): Promise<any> {
    let composedUrl = url;
    composedUrl = this.appendQueryParam(composedUrl, 'limit', limit);
    composedUrl = this.appendQueryParam(composedUrl, 'skip', skip);
    composedUrl = this.appendQueryParam(composedUrl, 'sortField', sortField);
    composedUrl = this.appendQueryParam(composedUrl, 'sortDirection', (sortDirection?.toUpperCase() ?? 'DESC'));

    // Add optional parameters
    //
    if (optionalParams) {
      Object.keys(optionalParams).forEach((key) => {
        if (!isUndefined(optionalParams[key])) {
          composedUrl = this.appendQueryParam(composedUrl, key, optionalParams[key]);
        }
      });
    }

    const request = new Request(this.generateUrl(composedUrl), {
      method: 'GET',
      headers: this.requestHeaders,
    });

    return this.makeApiCall(request);
  }

  genericPostCall(url: string, data: Object): Promise<any> {
    const request = new Request(this.generateUrl(url), {
      method: 'POST',
      body: JSON.stringify(data),
      headers: this.requestHeaders,
    });

    return this.makeApiCall(request);
  }

  genericPutCall(url: string, data: any): Promise<any> {
    const request = new Request(this.generateUrl(url), {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: this.requestHeaders,
    });

    return this.makeApiCall(request);
  }

  genericDeleteCall(url: string): Promise<any> {
    const request = new Request(this.generateUrl(url), {
      method: 'DELETE',
      headers: this.requestHeaders,
    });

    return this.makeApiCall(request);
  }

  handleError(error: any) {
    return Promise.reject(error);
  }
}

export const baseService = new BaseService();
