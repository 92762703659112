import {

  Modal,
  ModalBody,

  ModalHeader
} from "@windmill/react-ui";
import {
  forwardRef,
  useImperativeHandle,
  useRef, useState
} from "react";
import PlayerProfile from "../PlayerProfile/PlayerProfile";

interface ICompetitionsModalProps {
  playerId: number | null;
  removePlayer: Function;
}

const PlayerDetailsModal = forwardRef(
  ({ playerId, removePlayer }: ICompetitionsModalProps, ref) => {
    const [modalState, setModal] = useState(false);
    let modalRef: any = useRef();

    const setModalState = (boolean: boolean) => {
      setModal(boolean);
    };
    

    // This let's us expose the setModalState function to the parent
    // so we can handle opening and closing the modal from the parent
    // try to avoid using this too much as it's complex and sometimes
    // hard to read and can cause issues.
    // ## https://reactjs.org/docs/hooks-reference.html#useimperativehandle
    // for reference.
    //
    useImperativeHandle(
      ref,
      () => ({
        setModal: (boolean: boolean) => {
          setModalState(boolean);
        },
      }),
      []
    );


    return (
      <div ref={modalRef}>
        {playerId ? (
          <Modal isOpen={modalState} onClose={() => setModalState(false)} style={{minHeight: 576}} className="shadow-lg block bg-white p-4 md:w-3/5 sm:w-full">
            <ModalHeader className="text-2xl border-b-2 text-center pb-2">Edit player</ModalHeader>
            <ModalBody>
                <PlayerProfile playerId={playerId} removePlayer={removePlayer} />
            </ModalBody>
          </Modal>
        ) : null}
      </div>
    );
  }
);

export default PlayerDetailsModal;
