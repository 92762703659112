import { Button, Input } from "@windmill/react-ui";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { editAreaService } from "../../../api/services/edit-area.service";
import { editCompetitionService } from "../../../api/services/edit-competition.service";
import { IArea, ICompetition } from "../../../typings/primesports/primesports";

interface IMultiplierEditComponenet {
  area?: IArea;
  competition?: ICompetition;
}

const MultiplierEditComponent = ({
  area,
  competition,
}: IMultiplierEditComponenet) => {
  const [multiplier, setMultiplier] = useState("");
  const alert = useAlert();

  const handleMultiplierChange = () => {
    const multiplierNum = parseFloat(multiplier);

    if (competition && multiplier) {
      editCompetitionService
        .post(competition.id, multiplierNum)
        .then((response) => {
          alert.success("Successfully modified " + competition.name);
        })
        .catch((error) => {
          alert.error("Error modifing data", error);
        });
    }

    if (area && multiplier) {
      editAreaService
        .post(area.id, multiplierNum)
        .then((response) => {
          alert.success("Successfully modified " + area.name);
        })
        .catch((error) => {
          alert.error("Error modifing data", error);
        });
    }

    console.log(competition, area, " change multiplier ");
  };

  const onChangeMultiplier = (event: any) => {
    setMultiplier(event.target.value);
  };

  return (
    <div className="flex flex-row items-center">
      <div className="w-16">
        <Input
          css={{}}
          className="mt-1 border-gray-300 border-b-2 text-center appearance-none input"
          type="number"
          defaultValue={area ? area.multiplier : competition?.multiplier}
          onChange={(e) => onChangeMultiplier(e)}
        />
      </div>
      <Button
        size="small"
        className="h-8 ml-8"
        layout="outline"
        onClick={handleMultiplierChange}
      >
        Modify
      </Button>
    </div>
  );
};

export default MultiplierEditComponent;
