import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavbarComponent from "../Components/Navbar/Navbar";
import PasswordResetComponent from "../Components/PasswordResetComponent/PasswordResetComponent";
import Areas from "../Pages/Areas/Areas";
import Competition from "../Pages/Competitions/Competitions";
import Login from "../Pages/Login/Login";
import PlayerProfile from "../Pages/PlayerProfile/PlayerProfile";
import Players from "../Pages/Players/Players";
import Teams from "../Pages/Teams/Teams";
import UserProvider from "../Providers/UserProvider";
import PrivateRoute from "./PrivateRoute";

const SqoutRouter = () => {

  return (
    <React.Fragment>
      <Router>
        <UserProvider>
          <NavbarComponent />
          <Switch>

            <PrivateRoute component={Areas} path="/areas" />

            <Route path="/competitions" component={Competition} />

            <PrivateRoute path="/players" component={Players} />

            <PrivateRoute path="/player-profile" component={PlayerProfile} />

            <Route path="/teams" component={Teams} />

            <Route path="/login" component={Login} />
            <Route path="/passwordReset" component={PasswordResetComponent} />

            <PrivateRoute path="" component={Areas} />

          </Switch>
        </UserProvider>
      </Router>
    </React.Fragment>
  );
};

export default SqoutRouter;
