import { Button } from "@windmill/react-ui";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import sqoutLogo from "../../Assets/SqoutLogo.svg";
import { auth } from "../../firebase";
import { UserContext } from "../../Providers/UserProvider";
import { useAlert } from "react-alert";
import { triggerIndexService } from "../../api/services/index.service";
import { indexProgressService } from "../../api/services/index-progress.service";

const NavbarComponent = () => {
  const [indexProgress, setIndexProgress] = useState<number>(0);
  const [indexTotal, setIndexTotal] = useState<number>(0);
  const [indexStarted, setIndexStarted] = useState<boolean>(false);
  const [intervalStarted, setIntervalStarted] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation().pathname.slice(1);
  const [stateUser, setStateUser] = useState<{} | null>();

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        history.push("/login");
      })
      .finally(() => {
        setStateUser(null);
      });
  };

  // This gets called once to get things going
  //
  const startIndex = async () => {
    setIndexStarted(true);
    triggerIndexService
      .fetch()
      .then(() => {
        alert.success("Indexing has started");
      })
      .catch((error) => {
        console.error(error.message);
      });

    indexProgressService
      .fetch()
      .then((response) => {
        if (indexStarted) {
          setIndexTotal(response.job.total);
          setIndexProgress(response.job.current);
          startIndicator();
        }
      })
      .catch((e) => console.error(e));
  };

  // The initial index started check & trigger
  //
  const indexData = () => {
    // If indexStatus is complete and index button was pressed
    // trigger an index
    if (!indexStarted) {
      startIndex();
    }
  };

  const finishIndexing = useCallback(() => {
    setIndexProgress(0);
    setIndexTotal(0);
    setIntervalStarted(false);
    setIndexStarted(false);

    alert.success("Indexing has finished!", { timeout: 5000 });
  }, [alert]);

  const startIndicator = useCallback(() => {
    if (!intervalStarted) {
      console.log("start indicator");
      setIntervalStarted(true);

      const interval = setInterval(() => {
        console.log("fetch");
        indexProgressService.fetch().then((response) => {
          if (response.running) {
            setIndexTotal(response.job.total);
            setIndexProgress(response.job.current);
            setIndexStarted(response.running);
          } else {
            finishIndexing();
            clearInterval(interval);
          }
        });
      }, 5000);
    }
  }, [finishIndexing, intervalStarted]);

  useEffect(() => {
    if (user) {
      indexProgressService
        .fetch()
        .then((response) => {
          if (response.running) {
            setIndexTotal(response.job.total);
            setIndexProgress(response.job.current);
            setIndexStarted(true);
            startIndicator();
          }
        })
        .catch((e) => console.error(e));
    }
  }, [startIndicator, user]);

  useEffect(() => {
    if (user) {
      setStateUser(user);
    } else {
      setStateUser(null);
    }
  }, [user]);
  return (
    <React.Fragment>
      <div className="relative bg-white mb-6">
        <div className="flex flex-col items-center justify-center w-full shadow-md md:px-4 border-gray-100 py-6 md:justify-between md:flex-row">
          <a href="/">
            <img
              className="h-8 w-auto sm:h-10"
              src={sqoutLogo}
              alt="Sqout logo"
            />
          </a>

          {stateUser !== null ? (
            <>
              <nav className="flex flex-col flex-wrap items-center w-full px-4 mt-6 md:flex-row md:mt-0 gap-2 md:gap-0">
                <div
                  className={
                    location === "areas"
                      ? "border-b-2 border-sqoutGreen mx-4"
                      : "mx-4"
                  }
                >
                  <Link to="/areas">
                    <Button layout="link" className="w-full selected">
                      <span>Areas & Competitions</span>
                    </Button>
                  </Link>
                </div>

                <div
                  className={
                    location === "players"
                      ? "border-b-2 border-sqoutGreen mx-4"
                      : "mx-4"
                  }
                >
                  {" "}
                  <Link to="/players" className="mb-6">
                    <Button layout="link" className="w-full">
                      <span>Players</span>
                    </Button>
                  </Link>
                </div>

                <div className="w-full md:mx-auto md:mr-6 md:w-56">
                  {indexStarted ? (
                    <div className="md:w-full">
                      <div className="flex mb-2 items-center justify-between pt-4">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                            Index
                          </span>
                        </div>
                        <div className="text-right">
                          <span className="text-xs font-semibold inline-block text-sqoutGreen">
                            {indexProgress > 0
                              ? ((indexProgress * 100) / indexTotal).toFixed(2)
                              : 0}{" "}
                            %
                          </span>
                        </div>
                      </div>
                      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                        <div
                          style={{
                            width: `${(indexProgress * 100) / indexTotal}%`,
                          }}
                          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {!indexStarted ? (
                    <div className="mx-4 flex justify-center">
                      <Button onClick={indexData}>Index Data</Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </nav>
            </>
          ) : (
            <></>
          )}

          <div className="hidden md:flex justify-end w-24">
            <Button layout="outline" className="w-full">
              {stateUser !== null ? (
                <span onClick={() => signOut()}>Sign Out</span>
              ) : (
                <Link to="/login">
                  <span>Sign in</span>
                </Link>
              )}
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavbarComponent;
