import {
  Avatar,
  Input,
  Label,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableRow,
} from "@windmill/react-ui";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Pagination } from "tiny-ui";
import { searchPlayersService } from "../../api/services/search-players.service";
import { IPlayer } from "../../typings/search/search";
import PlayerDetailsModal from "./PlayerDetailsModal";

const LOCAL_STORAGE_SEARCH = "playerQuery";

const Players = () => {
  const [playersList, setPlayersList] = useState<IPlayer[]>([]);
  // const [paginatedList, setPaginatedList] = useState<IPlayer[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(
    localStorage.getItem(LOCAL_STORAGE_SEARCH) ?? ""
  );
  const [pageSize] = useState(15);
  const [showPlayerId, setShowPlayerId] = useState<number | null>(null);
  let playerModalRef: any = useRef(null);

  const fetchPlayers = useCallback(
    async (playerName: string, offset: number) => {
      try {
        const response = await searchPlayersService.post(
          playerName,
          offset,
          pageSize
        );
        const list = response.hits.hits.map((player: any) => player._source);
        setTotal(response.hits.total.value);
        setPlayersList(list);
        setQuery(playerName);
      } catch (e) {
        console.error(e);
      }
    },
    [pageSize]
  );

  const handlePagination = useCallback(
    (pageIndex: number) => {
      if (pageIndex !== page) {
        setPage(pageIndex);
        fetchPlayers(query, pageIndex * pageSize - pageSize);
      }
    },
    [page, fetchPlayers, query, pageSize]
  );

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_SEARCH, query);
  }, [query]);

  useEffect(() => {
    fetchPlayers(query, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showPlayer = (playerId?: number) => {
    if (playerId) {
      setShowPlayerId(playerId);
      playerModalRef.current.setModal(true);
    }
  };

  const getPlayerTeam = (player: IPlayer) => {
    if (player?.teams?.length === 1) {
      return player.teams[0].name;
    } else if (!isEmpty(player?.teams)) {
      let clubs = player.teams.filter((team: any) => team.type === "club");
      if (!isEmpty(clubs)) {
        if (clubs.length > 1) {
          return clubs
            .map((club: any) => {
              return club.name;
              // if both have a shirt then return higher multiplier club
              // TO:DO
            })
            .join(", ");
        } else {
          return clubs[0].name;
        }
      }
    }
  };

  const removePlayer = () => {
    setShowPlayerId(null);
  };

  return (
    <div className="flex flex-col w-full md:flex-row md:min-h-screen">
      <div>

        <PlayerDetailsModal
          playerId={showPlayerId ?? null}
          removePlayer={removePlayer}
          ref={playerModalRef}
        />
      </div>
      <div className="flex flex-col w-full text-gray-700 bg-white flex-shrink-0 border-l-2 md:w-64 ">
        <span className="text-lg font-semibold text-gray-900 uppercase rounded-lg">
          <Label className="p-1 md:border-b">
            <Input
              css={{}}
              className="mt-2 rounded mb-1"
              defaultValue={query}
              onChange={(event: any) => {
                fetchPlayers(event.target.value, 0);
                setPage(1);
              }}
              placeholder="Search for Player"
            />
          </Label>
        </span>
      </div>

      <TableContainer className="mt-6 md:mt-0 md:ml-6">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Height</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Birthdate</TableCell>
              <TableCell>Foot</TableCell>
              <TableCell>Team</TableCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            <React.Fragment>
              {playersList ? (
                playersList.map((player) => {
                  return (
                    <React.Fragment key={player.dsg_id}>
                      <TableRow onClick={() => showPlayer(player?.player_id)}>
                        <TableCell>
                          <div className="flex items-center text-md">
                            <Avatar
                              src={`https://www.dsg-images.com/players/50x50/${player.dsg_id}.png`}
                            />
                            <span className=" ml-2">{player?.common_name}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-md">
                            <span className="ml-2">{player?.height}cm</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-md">
                            <span className=" ml-2">{player?.weight}kg</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-md">
                            <span className=" ml-2">
                              {player?.date_of_birth}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-md">
                            <span className=" ml-2">
                              {player?.foot?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center text-md">
                            <span className=" ml-2">
                              {getPlayerTeam(player)}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })
              ) : (
                <></>
              )}
            </React.Fragment>
          </TableBody>
        </Table>
        <TableFooter>
          {total > pageSize && (
            <Pagination
              total={total}
              pageSize={pageSize}
              current={page}
              onChange={(page) => {
                handlePagination(page);
              }}
            />
          )}
        </TableFooter>
      </TableContainer>
    </div>
  );
};

export default Players;
