import React, { useCallback, useEffect, useState } from "react";
import { competitionService } from "../../api/services/competitions.service";
import { parentAreaService } from "../../api/services/parent-areas.service";
import { IArea, ICompetition } from "../../typings/primesports/primesports";
import AreasTableComponent from "./AreasTableComponent/AreasTableComponent";

enum AREA_NUM {
  world = 269,
  europe = 79,
  asia = 15,
  africa = 2,
  south_america = 222,
  north_central_america = 160,
  oceania = 177,
}

const Areas = () => {
  const [selectedAreaNumber, setSelectedAreaNumber] = useState<AREA_NUM>(269);
  const [selectedArea, setSelectedArea] = useState<IArea[]>([]);
  const [worldCompetitions, setWorldCompetitions] = useState<ICompetition[]>([]);

  const setTabAndCallData = (areaNum: number) => {
    setSelectedAreaNumber(areaNum);

    fetchAreas(areaNum);
  };

  const fetchAreas = useCallback(async (parentId: number) => {
    if (parentId === AREA_NUM.world) {
      const worldResponse = await competitionService.fetch(parentId);
      setWorldCompetitions(worldResponse);
    } else {
      const areasResponse = await parentAreaService.fetch(parentId);
      setSelectedArea(areasResponse);
    }
  }, []);

  useEffect(() => {
    fetchAreas(selectedAreaNumber);
  }, [selectedAreaNumber, fetchAreas]);

  return (
    <React.Fragment>
      <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white flex-shrink-0">
          <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
            <span className="text-lg text-gray-900 uppercase rounded-lg">
              Areas
            </span>
          </div>
          <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
            <span
              onClick={() => setTabAndCallData(AREA_NUM.world)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 cursor-pointer rounded-lg focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.world
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              World
            </span>
            <span
              onClick={() => setTabAndCallData(AREA_NUM.europe)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 rounded-lg cursor-pointer  focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.europe
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              Europe
            </span>
            <span
              onClick={() => setTabAndCallData(AREA_NUM.asia)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 rounded-lg cursor-pointer  focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.asia
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              Asia
            </span>
            <span
              onClick={() => setTabAndCallData(AREA_NUM.africa)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 rounded-lg cursor-pointer  focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.africa
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              Africa
            </span>
            <span
              onClick={() => setTabAndCallData(AREA_NUM.south_america)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 rounded-lg cursor-pointer  focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.south_america
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              South America
            </span>

            <span
              onClick={() => setTabAndCallData(AREA_NUM.north_central_america)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 rounded-lg cursor-pointer  focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.north_central_america
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              N/C America
            </span>

            <span
              onClick={() => setSelectedAreaNumber(AREA_NUM.oceania)}
              className={
                "block px-4 py-2 mt-2 text-sm text-gray-900 rounded-lg cursor-pointer  focus:text-gray-900 hover:bg-gray-200 " +
                (selectedAreaNumber === AREA_NUM.oceania
                  ? "border-l-4 border-sqoutGreen"
                  : "")
              }
            >
              Oceania
            </span>
          </nav>
        </div>

        <AreasTableComponent areasData={selectedAreaNumber !== AREA_NUM.world ? selectedArea : undefined } worldCompetitionsData={selectedAreaNumber === AREA_NUM.world ? worldCompetitions : undefined} />
      </div>
    </React.Fragment>
  );
};

export default Areas;
