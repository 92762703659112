import { baseService, BaseService } from '../base-api';



export class ValidateUserService {

  constructor(private baseService: BaseService) {}

  get() {
    try {
      return this.baseService.genericGetCall(`/auth/validate`)
        .then((response: any) => {
          return response;
        });
    } catch (error) {
      return this.baseService.handleError(error);
    }
  }
}

export const validateUserService = new ValidateUserService(baseService);