import React from "react";

const Competition = () => {

  return (
    <React.Fragment>
      <h2>
        Competitions!
      </h2>
    </React.Fragment>
  );
}

export default Competition;